<template>
    <div class="container_con">
        <el-card>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addAddressItem')" v-if="have_auth('/address/add')">+ 新增</el-button>
                    <el-button type="primary" icon="el-icon-s-delete" :disabled="multiple" @click="handleDelete"  v-if="have_auth('/address/del')">删除</el-button>
                </div>
                <div>
                    
                    <el-input icon="el-icon-search" v-model="queryParams.title" @keyup.enter.native="handleQuery" placeholder="请输入联系人" clearable
                        style="width:300px;margin-right:10px"></el-input>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                    <el-button @click="resetQuery()">重置</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="title" label="收货人" />
                    <el-table-column prop="mobile" label="手机号" />
                    <el-table-column prop="province_name" label="省" />
                    <el-table-column prop="city_name" label="市" />
                    <el-table-column prop="district_name" label="区" />
                    <el-table-column prop="street_name" label="街道" />
                    <el-table-column prop="detail" label="详细地址" />
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleUpdate(scope.row)"  v-if="have_auth('/address/edit')">编辑</el-button>
                            <!-- <el-button type="text" size="small" @click="handleDelete(scope.row)"  v-if="have_auth('/address/del')">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "department",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    title: ''
                },
                total:0,
                list:[],
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true
            };
        },
        created() {
            this.queryParams=window[this.$route.path] || {
              page: 1,
              size: 10,
              title: ''
            }
            this.getList();
        },
        watch: {
          $route(to,form) {
              window[form.path]=this.queryParams
          },
        },
        methods: {
            resetQuery(){
                  this.queryParams= {
                      page: 1,
                      size: 10,
                      title: ''
                  }
                  this.getList()
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
              this.ids = selection.map(item => item.id);
              this.single = selection.length != 1;
              this.multiple = !selection.length;
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addAddressItem?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  _this.$httpGet("/backend/ShippingAddress/del", {id:ids.join(',')}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/ShippingAddress/index", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
